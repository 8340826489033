<template>
  <div>

    <!--悬浮按钮-->
    <Contact/>
    <section class="slice bgf5">
      <div class="col-lg-12 t-center">
        <h3 class="h3-cp">SPDM产品数据管理系统</h3>
        <p class="lead my-4">
          <a href="javascript:;">全面提升企业数字化协同设计能力</a>
        </p>
        <p class="lead my-4" style="width: 80vw;margin: 0 auto;text-align: initial;color: #fff;">
          SPDM产品数据管理系统用于管理产品从概念设计到退役的整个生命周期的系统。SPDM系统集成了各个环节的数据、流程和人员，帮助企业全面管理和控制产品的研发过程。通过使用SPDM系统，企业可以更好地管理产品的整个生命周期，提高产品质量、加速上市时间并降低成本。它可以促进团队协作，优化工作流程，并提供决策支持和数据分析功能，有助于企业在竞争激烈的市场中取得优势。
          SPLM研发管理系统常用功能包括BOM管理、三维数据管理、文档管理、编码管理、版本管理、生命周期管理、流程管理、变更管理、权限管理等。
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="item in dataList" :key="item.alt">
          <img :alt="item.alt" :src="item.img"/>
          <p>{{ item.alt }}</p>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
        <div slot="button-prev" class="swiper-button-prev"></div>
        <div slot="button-next" class="swiper-button-next"></div>
      </swiper>
    </section>


    <section class="slice slice-lg" style="padding-top:30px;">
      <div class="container">
        <div class="col-lg-12 t-center">
          <h3>SPDM产品优势</h3>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">云原生技术</h5>
                <p class=" mt-2 mb-0">
                  微服务架构<br/>
                  容器化部署<br/>
                  自动化管理<br/>
                  面向服务的架构

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">多种实体文件存储方式</h5>
                <p class=" mt-2 mb-0">
                  MongoDB<br/>
                  FastDFS<br/>
                  minIO
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">可配置的业务模型</h5>
                <p class=" mt-2 mb-0">
                  类型和关系类型<br/>
                  顶层类型可配置<br/>
                  关系类型可配置
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">签署配置实时预览</h5>
                <p class=" mt-2 mb-0">
                  签署模板管理<br/>
                  签署信息管理<br/>
                  实时预览，提前感知
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">定制化表单在线编制</h5>
                <p class=" mt-2 mb-0">
                  自定义表单配置<br/>
                  在线管理<br/>
                  实时生效
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">配置化集成工具</h5>
                <p class=" mt-2 mb-0">
                  设计工具配置管理<br/>
                  客户端免安装<br/>
                  客户端同步更新
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">云服务</h5>
                <p class=" mt-2 mb-0">
                  跨域协同<br/>
                  弹性伸缩<br/>
                  高安全性和稳定性
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">聚焦式管理</h5>
                <p class=" mt-2 mb-0">
                  工作中心、任务、消息集中管理<br/>
                  便捷式操作<br/>
                  无需频繁切换页面
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>


export default {
  name: 'SPDM',
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 70,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataList: [
        {img: require('../img/index/2/pro1.png'), alt: 'BOM管理'},
        {img: require('../img/index/2/pro2.png'), alt: '三维数据管理'},
        {img: require('../img/index/2/pro3.png'), alt: '变更管理'},
        {img: require('../img/index/2/pro4.png'), alt: '流程管理'},
        {img: require('../img/index/2/pro5.png'), alt: '基线管理'},
        {img: require('../img/index/2/pro6.png'), alt: '文档管理'}
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/my.scss";

.bgf5 {
  /* background:#f5f5f5; */
  background: url('../img/login/2.jpg') no-repeat;
  background-size: 100% 100%;
}

</style>
